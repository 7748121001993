<template>
  <div class="terms">
    <section class="mainarea">
      <h2 class="ttl">Resemom相談online利用規約（講師用）</h2>
      <p>
        この利用規約（以下、「本利用規約」といいます）は、株式会社イード（以下、「当社」といいます）が運営する「Resemom相談online」（以下、「本サービス」といいます）の講師登録者と当社との間の権利義務関係に適用されます。
      </p>
      <p>
        なお、本利用規約に加え、ガイドラインやその他のルール（以下、総称して「ルール等」といいます）が定められることがあります。当社が本サービス上で随時掲載する本サービスに関するルール等は、本規約の一部を構成するものとします。
      </p>
      <p>
        本利用規約の改定・変更
        当社は必要に応じて、本利用規約を変更できるものとします。変更後の本利用規約は、当該掲載が成された時点で効力が生じるものとし、講師登録者が本利用規約発効後に本サービスを利用した場合、講師登録者が本利用規約の変更を承諾したものとみなします。
      </p>

      <h3 class="subttl">本サービス</h3>
      <p>
        本サービスは、講師登録者に対して有料相談サービスの販売機会を提供するものです。当社は、本サービスのシステム及び情報の提供、本サービス利用者からの利用料金の回収業務並びに講師登録者への支払い業務を行います。個別の相談の申込に関する契約（以下「個別相談契約」といいます）は、本サービス利用者と講師登録者との間で成立するものとし、当社の責に帰すべき事由による場合を除き、当社は個別相談契約の内容について責任を負いません。
      </p>

      <h3 class="subttl">本サービスの変更・停止・終了</h3>
      <p>
        災害、労働争議、停電、本サービスのシステムの故障、その他のやむを得ない事情、または当社の都合により、本サービス自体または一部を停止することがあります。この場合、当社は本サービスの追加・変更・中断・廃止によって講師登録者に不利益または損害が発生したとしても、一切の責任を負いません。また、当社は事前に本サービスの終了を講師登録者に通知することができます。
      </p>

      <h3 class="subttl">利用環境の整備</h3>
      <p>
        講師登録者は、本サービスを利用するために必要な機器、ソフトウェア、通信手段等を自己の責任と費用で適切に整備するものとします。講師登録者が自身の利用環境の故障・中断・停止等の理由により不利益や損害が発生しても、当社は一切責任を負いません。
      </p>

      <h3 class="subttl">講師登録</h3>
      <p>
        本サービスの登録を希望する場合、講師登録者は本利用規約に同意し、当社の定める手続に従って登録を行うものとします。なお、本サービスへの登録は無料です。登録手続きを行うことができるのは、本人（法人の場合には対外的な契約権限を有する者）に限られ、代理人による登録は認められません。登録した情報については、その内容の正確性、真実性、最新性等について講師登録者自身が責任を負うものとします。
      </p>
      <p>
        講師登録の資格・条件
        講師として登録できる者の資格・条件は以下の通りです。
      </p>
      <ol class="decimal">
        <li>
          自身の選択した登録職種に応じて必要な経験や職務資格を保有していること
        </li>
        <li>登録する情報が登録者自身の情報であり、虚偽ではないこと</li>
        <li>既に本サービスに登録していないこと</li>
        <li>本利用規約の全ての条項に同意すること</li>
        <li>
          過去、現在、または将来にわたって、暴力団等の反社会的勢力に所属せず、かつ、これらと密接な関係を有していないこと
        </li>
      </ol>
      <p>
        当社は、以下のいずれかに該当する場合、登録を承認しないことがあります。
      </p>
      <ol class="decimal">
        <li>当社所定の方法・様式以外で登録申請をした場合</li>
        <li>
          登録内容に虚偽の情報がある場合、または虚偽である可能性が高いと当社が判断した場合
        </li>
        <li>
          登録の資格・条件を満たしていない場合、または満たさなくなった場合
        </li>
        <li>当社から登録の取り消しを受けたことがある場合</li>
        <li>その他、当社が不適切と判断した場合</li>
      </ol>

      <h3 class="subttl">登録の取消し</h3>
      <p>
        当社は、講師登録者が以下のいずれかに該当する場合、当社の判断により登録を取り消すことができます。また、登録の取消しによって講師登録者に不利益や損害が発生しても、当社は一切責任を負いません。
      </p>
      <ol class="decimal">
        <li>当社所定の方法・様式以外で登録申請をした場合</li>
        <li>
          登録内容に虚偽の情報がある場合、または虚偽である可能性が高いと当社が判断した場合
        </li>
        <li>登録の資格・条件を満たさない場合、または満たさなくなった場合</li>
        <li>当社または本サービス利用者からの連絡が取れなくなった場合</li>
        <li>当社から登録の取り消しを受けたことがある場合</li>
        <li>本利用規約に違反した場合</li>
        <li>その他、当社が不適切と判断した場合</li>
      </ol>

      <h3 class="subttl">登録情報の変更</h3>
      <p>
        講師登録者は、登録情報に変更が生じた場合、当社の指定する方法によって速やかに本システム上で登録情報の変更を行うものとします。講師登録者が変更の届出を行わなかったことによる不利益が生じた場合、当社はその一切の責任を負いません。
      </p>

      <h3 class="subttl">退会</h3>
      <p>
        講師登録者は、本サービスへの登録終了を希望する場合、当社所定の手続きを行うものとします。ただし、個別相談契約が終了していない状況や完了した個別相談契約の対価支払いが完了していない状況では、講師登録者と当社との間で協議の上、登録終了の手続きを行うものとします。
      </p>

      <h3 class="subttl">対価の支払い</h3>
      <p>
        当社は、講師登録者に対して、個別相談契約に基づき本サービス利用者から支払われた利用料金から、講師登録者の本サービス利用料（30%）を差し引いた金額を個別相談契約に基づく対価として支払います。対価の支払いは、講師登録者が本サービス登録時に指定した銀行口座に振り込まれ、振込手数料は当社が負担します。
      </p>

      <h3 class="subttl">ログイン用メールアドレスおよびパスワードの管理</h3>
      <p>
        講師登録者は、本サービスを利用するために、不正使用されないログイン用メールアドレスおよびパスワードを登録するものとします。登録されたログイン用メールアドレスおよびパスワードは、講師登録者の責任において適切に管理し、第三者に開示または貸与することはできません。当社は、ログイン用メールアドレスおよびパスワードの不正利用によって生じた講師登録者の損害について責任を負いません。また、ログイン用メールアドレスおよびパスワードを用いて本サービスへ認証した後の行為は、講師登録者自身の行為とみなされます
      </p>

      <h3 class="subttl">保証と責任</h3>
      <p>
        講師登録者は、本サービス上に登録した経験や資格を保証するものとし、善良な管理者の注意義務をもって個別相談契約を履行するものとします。講師登録者は、法令や当社が定めるルール・規約に従って本サービスを利用し、提供するサービスが第三者の著作権や知的財産権、肖像権、プライバシー権、パブリシティ権などの権利を侵害していないことや、その内容に第三者の誹謗中傷、公序良俗違反、虚偽情報を含まないことを保証します。もし講師登録者の保証違反によって当社または本サービス利用者から第三者による異議が申し立てられた場合、講師登録者は自身の費用と責任で異議を解決し、当社や本サービス利用者に迷惑をかけないものとします。
      </p>

      <h3 class="subttl">再委託の禁止</h3>
      <p>
        講師登録者は、個別相談契約の全部または一部を第三者に再委託することはできません。
      </p>

      <h3 class="subttl">秘密情報の保持</h3>
      <p>
        講師登録者は、本利用規約又は個別相談契約履行の過程で知り得た当社や本サービス利用者の秘密情報（本サービス利用者の個人情報を含む）を秘密に保持し、本サービスの利用や個別相談契約の履行の目的以外でその情報を使用しないものとします。また、秘密情報を第三者に開示や漏洩してはなりません。
      </p>

      <h3 class="subttl">契約上の地位や譲渡等の禁止</h3>
      <p>
        講師登録者は、本利用規約に基づく権利や義務、契約上の地位の一部または全部を第三者に譲渡、承継、移転、質入れ、または他の方法によって処分してはなりません。
      </p>
      <p>
        禁止事項講師登録者は、本サービスの登録や利用、個別相談契約の履行において以下の行為を行ってはなりません。
      </p>
      <ol class="decimal">
        <li>虚偽や不正確な情報を提供する行為</li>
        <li>本サービス利用者に不利益や不信感を与える行為</li>
        <li>
          第三者の知的財産権（商標権、著作権、意匠権、特許権など）を侵害する行為
        </li>
        <li>第三者の財産、信用、名誉、プライバシーを侵害する行為</li>
        <li>
          第三者や特定の業者・商品を誹謗中傷し、社会的信用を低下させる行為
        </li>
        <li>
          暴力的な要求行為や法的責任を超えた不当な要求行為、脅迫的な言動を用いる行為、または暴力を用いる行為=
        </li>
        <li>
          当社の本サービスの運営を妨げたり、信用を毀損または営業を妨害する行為
        </li>
        <li>公序良俗に反する行為</li>
        <li>犯罪行為を助長したり、その実行を暗示する行為</li>
        <li>法令やガイドライン、規則などに違反する行為</li>
        <li>本利用規約に違反する行為</li>
        <li>その他、当社が不適切と判断する行為</li>
      </ol>
      <p>
        講師登録者は、当社の許諾を得ることなく、本サービスを通じて個別相談契約が成立した本サービス利用者に対して、本サービスと同等の役務提供を直接行ってはなりません。
      </p>

      <h3 class="subttl">免責事項</h3>
      <p>
        当社は、本サービスにおける提供情報の正確性、適時性、完全性、有用性、適法性について一切責任を負いません。講師登録者は自己の判断に従って本サービスを利用してください。本サービスの利用中や変更、中断、終了などによって生じた不利益や損害について、当社は一切責任を負いません。機器の故障、人災、天災などによってサービス提供が中断、停止、提供情報の遅配などが生じた場合も同様です。また、講師登録者が本サービスを利用することによって生じた他の講師登録者や本サービス利用者との紛争やトラブルについても、当社は一切責任を負いません。
      </p>

      <h3 class="subttl">損害賠償</h3>
      <p>
        講師登録者が本利用規約又は個別相談契約に反した行為又はその他の違法な行為をすることによって当社又は本サービス利用者が損害を受けた場合、当社は講師登録者に対して、損害賠償請求（合理的範囲の弁護士費用を含む）ができるものとし、講師登録者は、当該損害を賠償するものとします。
      </p>

      <h3 class="subttl">協議事項</h3>
      <p>
        本利用規約に定めのない事項について､当社と講師登録者との間に疑義が生じた場合には､双方誠意をもって協議解決するものとします｡
      </p>

      <h3 class="subttl">準拠法および管轄裁判所</h3>
      <p>
        本利用規約の解釈にあたっては、日本法を準拠法とします。本サービスに関して紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <p>
        付則<br />
        施行 2024年1月15日
      </p>
    </section>
    <MetaTags />
  </div>
</template>
<script>
export default {
  name: "Terms",
};
</script>
<style scoped></style>
